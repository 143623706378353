import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
import { useLocation } from "react-router-dom";
import Reservation from '../Modal/reservation';

let apiServices = new ApiService();
function Header(props) {
        const location = useLocation();
        const [settingData, setSettingData] = useState('')
        const [imageUrl, setImageUrl] = useState('')
        const [defaultImage, setDefaultImage] = useState('')
        const didMountRef = useRef(true);
        const [show, setShow] = useState(false)
        const [showLogin, setShowLogin] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        useEffect(() => {
                if (didMountRef.current) {
                        apiServices.settingsdataGetRequest().then(res => {
                                if (res.data.status == "success") {
                                        setImageUrl(res.data.settings_url)
                                        setSettingData(res.data.sitesettings)
                                        setDefaultImage(res.data.default_image_baseurl)
                                }
                        })
                }
                didMountRef.current = false;
        }, [])

        return (
                <>
                        <div className="progress-wrap cursor-pointer" >
                                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                                </svg>
                        </div>
                        <div class="top-header"><div class="container"><div class="row justify-content-center align-items-center"><div class="col-lg-12"><marquee>{settingData?.marquee_tag}</marquee></div></div></div></div>
                        {/* <div className="sidebutton">
                                <a href="https://widget.servmeco.com/?oid=718" className="reserbutton" target='_blank'>Reservations</a>
                                <a href="/special-offers" className="whatsnewbutton">Special Offers</a>
                        </div> */}

                        <nav className="navbar navbar-expand-md">
                                <div className="container-fluid">
                                        <div className="logo-wrapper mlogo">
                                                <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                                        </div>
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ti-menu"></i></span> </button>
                                        <div className="collapse navbar-collapse" id="navbar">
                                                <ul className="navbar-nav mx-auto">

                                                        <li className="nav-item"><a href="/" className={location.pathname.includes("/") ? "nav-link active" : "nav-link"}>Home</a></li>
                                                        {/* <li className="nav-item"><a href="/kalagram/" className={location.pathname.includes("/kalagram/") ? "nav-link active" : "nav-link"}>Desi Store</a></li> */}
                                                        <li className="nav-item"><a href="/entertainment" className={location.pathname.includes("/entertainment") ? "nav-link active" : "nav-link"}>Entertainment</a></li>

                                                        <li className="nav-item"><a href="/special-offers" className={location.pathname.includes("/special-offers") ? "nav-link active" : "nav-link"}>Special Offers</a></li>
                                                        <li className="nav-item"><a href="/craft-of-india" className={location.pathname.includes("/craft-of-india") ? "nav-link active" : "nav-link"}>Craft Of India</a></li>

                                                        <div className="logo-wrapper mclogo">
                                                                <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>

                                                        </div>
                                                        <li className="nav-item"><a href="/menu" className={location.pathname.includes("/menu/") ? "nav-link active" : "nav-link"}>Menu</a></li>

                                                        <li className="nav-item"><a href="/indian-street-food-deck" className={location.pathname.includes("/indian-street-food-deck") ? "nav-link active" : "nav-link"}>Deck</a></li>

                                                        {/* <li className="nav-item"><a href="/about" className={location.pathname.includes("/about/") ? "nav-link active" : "nav-link"}>About Us</a></li> */}

                                                        <li className="nav-item"><a href="/events" className={location.pathname.includes("/events") ? "nav-link active" : "nav-link"}>Events</a></li>
                                                        {/* <li className="nav-item"><a href="/blogs" className={location.pathname.includes("/blogs") ? "nav-link active" : "nav-link"}>Blog</a></li> */}
                                                        <li className="nav-item"><a href="/reservations" className={location.pathname.includes("/reservations") ? "nav-link active" : "nav-link"}>Reservations</a></li>
                                                        <li className="nav-item"><a href="/contact" className={location.pathname.includes("/contact") ? "nav-link active" : "nav-link"}>Contact</a></li>

                                                        {/* <li className="nav-item mres"><a href="/reservations" className={location.pathname.includes("/reservations") ? "nav-link active" : "nav-link"}>Reservations</a></li> */}
                                                        <div className="butn-dark"> <a className='reservebutton' href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reserve a Table</span></a> </div>

                                                </ul>
                                        </div>
                                </div>
                        </nav>
                        <header className="header">
                                <div className="video-fullscreen-wrap">

                                        <div className="video-fullscreen-video" data-overlay-dark="3">
                                                <video src={'assets/video/CDDubaiVideo.mp4'} autoPlay="autoplay" loop muted playsInline >
                                                </video>
                                        </div>
                                        <div>
                                                <audio autoPlay="autoplay">
                                                        <source src={'assets/video/chokhidhanidubaiaudio.mp3'} type="audio/mpeg" />
                                                </audio>
                                        </div>
                                        {/* <YouTube videoId="4h1WfbXDF5M" opts={opts} onReady={onReady} autoplay="autoplay" loop/> */}

                                        {/* */}
                                        {/* <YouTube videoId="https://youtu.be/4h1WfbXDF5M" opts={opts} onReady={onPlayerReady} />; */}

                                        {/*<video src={'assets/video/CDDubaiVideo.mp4'} autoPlay="autoplay" loop  playsInline >
</video> */}


                                        <div className="v-middle caption overlay">
                                                <div className="container">
                                                        <div className="row">
                                                                <div className="col-md-10 offset-md-1">
                                                                        <h1> An Exotic Journey through India's Food Culture</h1>
                                                                        <div className="butn-dark"><a href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reservations</span></a> </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className="reservation">
                                        <a href={"tel:" + settingData.admin_support_mobile}>
                                                <div className="icon d-flex justify-content-center align-items-center">
                                                        <i className="flaticon-call"></i>
                                                </div>
                                                <div className="call" ><span>{settingData.admin_support_mobile}</span> <br />
                                                </div>
                                        </a>
                                </div>
                                <div className="arrow bounce text-center">
                                        <a href="#" data-scroll-nav="1" className=""> <i className="ti-arrow-down"></i> </a>
                                </div>
                        </header>
                        {/* <Modal show={show} onHide={handleClose} size="lg">
<Modal.Body className="login-modal-content"  >
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>                           
</Modal.Body>
</Modal> */}
                        <Reservation

                                show={show}
                                setShow={setShow}
                                handleClose={handleClose}
                                handleShow={handleShow}
                        />

                </>
        )
}
export default Header
